import React, { useEffect, useState } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../images/logo.png";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import Loader from "../../components/Loader";

import {
  useFirebaseApp,
  useFirestore,
  useFirestoreCollectionData,
  useUser,
} from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { navigate } from "gatsby-link";
import { useForm } from "react-hook-form";

const Admin = (props) => {
  const firebase1 = useFirebaseApp();
  const firestore = useFirestore(firebase1);

  const [signedIn, setSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState({
    search: "",
    filterStatus: "",
  });

  let admin = false;
  let signedIn1 = false;

  let catRef = "";

  console.log("Filtered Status: " + JSON.stringify(filteredStatus));
  if (filteredStatus.filterStatus == "Accepted") {
    catRef = firestore
      .collection("Users")
      .where("verified", "==", "accepted")
      .orderBy("timestamp", "desc");
  } else if (filteredStatus.filterStatus == "Declined") {
    catRef = firestore
      .collection("Users")
      .where("verified", "==", "declined")
      .orderBy("timestamp", "desc");
  } else if (filteredStatus.filterStatus == "Pending") {
    catRef = firestore
      .collection("Users")
      .where("verified", "==", "empty")
      .orderBy("timestamp", "desc");
  } else {
    catRef = firestore.collection("Users").orderBy("timestamp", "desc");
  }

  let { data } = useFirestoreCollectionData(catRef);

  const { data: user } = useUser();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const signOut = () => {
    window.localStorage.setItem("isSignedIn", false);
    navigate("/Login", { state: { from: "signOut" } });
  };

  useEffect(() => {
    if (
      window.localStorage.getItem("isSignedIn") != undefined &&
      window.localStorage.getItem("isSignedIn") == "true"
    ) {
      console.log("User Object: " + JSON.stringify(user));
      // setSignedIn(true);
      // signedIn = true;

      signedIn1 = true;

      getFirestoreData(user);
    } else {
      navigate("/Login");
    }

    console.log("Useeffect1 ke andar: " + admin);
  }, [user, window.localStorage.getItem("isSignedIn")]);

  const getFirestoreData = async (user) => {
    if (user != undefined) {
      await firestore
        .collection("Users")
        .where("email", "==", user.email)
        .get()
        .then((snapshot) => {
          if (snapshot.docs[0].data().admin != undefined) {
            // setIsAdmin(true)
            // isAdmin = true
            admin = true;
            console.log("snapshot ki condtion: true");
          } else {
            // setIsAdmin(false);
            // isAdmin = false;
            admin = false;
            navigate("/Login");
            console.log("snapshot ki condtion: false");
          }
        });

      setSignedIn(signedIn1);
      setIsAdmin(admin);
    } else {
      return <Loader />;
    }
  };

  const onSubmit = (data) => {
    console.log("Data: " + JSON.stringify(data));
  };

  const filterUserStatusChange = (e) => {
    e.preventDefault();

    setFilteredStatus({
      ...filteredStatus,
      [e.target.name]: e.target.value,
      error: "",
    });

    console.log("Changed User: " + e.target.value);
  };

  if (signedIn) {
    const getUsers = (user) => {
      if (data != undefined) {
        console.log("data: " + JSON.stringify(data));
        return user.map((userData) => {
          return (
            <tr>
              <td scope="row">{userData.name}</td>
              <td>{userData.number}</td>
              <td>{userData.email}</td>
              <td>{userData.enquiry}</td>
              <td>{userData.enquiry}</td>
              <td>{userData.country}</td>

              {userData.verified == "empty" ? (
                <div>
                  <td>
                    <Button
                      className="ac_btn"
                      onClick={() => acceptUser(userData)}
                    >
                      Accept
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="dc_btn"
                      onClick={() => declineUser(userData)}
                    >
                      Decline
                    </Button>
                  </td>
                </div>
              ) : userData.verified == "accepted" ? (
                <div>
                  <td>
                    <Button className="ac_btn" disabled>
                      Accepted
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="dc_btn"
                      onClick={() => declineUser(userData)}
                    >
                      Decline
                    </Button>
                  </td>
                </div>
              ) : (
                    <div>
                      <td>
                        <Button
                          className="ac_btn"
                          onClick={() => acceptUser(userData)}
                        >
                          Accept
                    </Button>
                      </td>
                      <td>
                        <Button className="dc_btn" disabled>
                          Declined
                    </Button>
                      </td>
                    </div>
                  )}
            </tr>
          );
        });
      }
    };

    const acceptUser = (userData) => {
      firestore
        .collection("Users")
        .where("email", "==", userData.email)
        .get()
        .then((snapshot) => {
          console.log(JSON.stringify(snapshot.docs[0].id));

          firestore
            .collection("Users")
            .doc(snapshot.docs[0].id)
            .update({ verified: "accepted" })
            .then(() => {
              console.log("Updated");
            });
        });
    };

    const declineUser = (userData) => {
      firestore
        .collection("Users")
        .where("email", "==", userData.email)
        .get()
        .then((snapshot) => {
          console.log(JSON.stringify(snapshot.docs[0].id));

          firestore
            .collection("Users")
            .doc(snapshot.docs[0].id)
            .update({ verified: "declined" })
            .then(() => {
              console.log("Updated");
            });
        });
    };

    return (
      <div className="admin">
        <div className="admin_header">
          {/* <Container> */}
          <Row>
            <Col md="4">
              <div className="admin_logo">
                <img src={Logo} alt="img" />
              </div>
            </Col>
            <Col md="4">
              <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup row className="Search">
                    <Col sm={3} className="main_admin">
                      {/* <Input placeholder="Search" /> */}
                    </Col>

                    <Col sm={9} className="main_admin">
                      <Input
                        type="select"
                        name="filterStatus"
                        id="exampleStatus"
                        onChange={filterUserStatusChange}
                      >
                        <option style={{ color: "#000" }}>All Users</option>
                        <option value="Accepted">Accepted Users </option>
                        <option value="Declined">Declined Users </option>
                        <option value="Pending">Pending Users</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
            </Col>
            <Col md="4">
              <div className="admin_text">
                <p onClick={() => signOut()}>Log Out</p>
              </div>
            </Col>
          </Row>
          {/* <div className="admin_row">
              <div className="admin_logo">
                <img src={Logo} alt="img" />
              </div>
              <div>
                <input placeholder="search"/>
              </div>
              <div className="admin_text">
                <p onClick={() => signOut()}>Log Out</p>
              </div>
            </div> */}
          {/* </Container> */}
        </div>
        <Row>
          <Col md="2">
            <section className="left_section">
              <div>
                <ul>
                  <li>
                    <a href="/admin/">Listed Products</a>
                  </li>
                  <li>
                    <a href="/admin/AddedProduct/">Add a Product</a>
                  </li>
                  <li>
                    <a href="/admin/NewRegisters/">Users</a>
                  </li>
                </ul>
              </div>
            </section>
          </Col>
          <Col md="10">
            <section className="right_section">
              <div style={{ color: '#000', textTransform: 'uppercase' }}>New Registers</div>
              <Row>
                <Col md="12">
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Enquiry</th>
                        <th>Query</th>
                        <th>Country</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{data != undefined && getUsers(data)}</tbody>
                  </Table>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>

      </div>
    );
  } else {
    return null;
  }
};

export default Admin;
